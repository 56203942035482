
import { Vue, Component, Ref } from 'vue-property-decorator'
import { RuleDetail } from '@/types/onlineStatistics'
import { Page } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component
export default class RuleSettings extends Vue {
  @Ref('form') readonly elForm!: ElForm

  page = 1
  size = 10
  total = 0
  loading = false
  tableData: RuleDetail[] = []
  currentData: RuleDetail = {
    projectId: '',
    timeH: '',
    timeM: '',
    timeDuration: 0,
    timeStartOne: '',
    timeEndOne: '',
    timeStartTwo: '',
    timeEndTwo: '',
    timeStartThree: '',
    timeEndThree: '',
    timeStartFour: '',
    timeEndFour: '',
    timeStartFive: '',
    timeEndFive: '',
    timeStartSix: '',
    timeEndSix: '',
    ctime: '',
    isDel: 0,
    timeRange: []
  }

  rules = {}

  timeRange: string[][] = []

  dialogVisible = false

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get<Page<RuleDetail>>(this.$apis.bracelet.selectWorkerRulesByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    })
  }

  onEdit (row: RuleDetail) {
    const currentData = JSON.parse(JSON.stringify(row)) as RuleDetail
    const range: string[][] = []
    range.push([(currentData.timeStartOne || ''), (currentData.timeEndOne || '')])
    if (currentData.timeStartTwo || currentData.timeEndTwo) {
      range.push([(currentData.timeStartTwo || ''), (currentData.timeEndTwo || '')])
    }
    if (currentData.timeStartThree || currentData.timeEndThree) {
      range.push([(currentData.timeStartThree || ''), (currentData.timeEndThree || '')])
    }
    if (currentData.timeStartFour || currentData.timeEndFour) {
      range.push([(currentData.timeStartFour || ''), (currentData.timeEndFour || '')])
    }
    if (currentData.timeStartFive || currentData.timeEndFive) {
      range.push([(currentData.timeStartFive || ''), (currentData.timeEndFive || '')])
    }
    if (currentData.timeStartSix || currentData.timeEndSix) {
      range.push([(currentData.timeStartSix || ''), (currentData.timeEndSix || '')])
    }
    currentData.timeRange = range
    this.currentData = currentData
    this.timeRange = range
    this.dialogVisible = true
  }

  addTime () {
    this.timeRange.push(['', '23:00'])
  }

  delTime (index: number) {
    this.timeRange.splice(index, 1)
  }

  save () {
    const currentData = JSON.parse(JSON.stringify(this.currentData)) as RuleDetail
    const len = this.timeRange.length
    currentData.timeStartOne = len > 0 ? this.timeRange[0][0] : ''
    currentData.timeEndOne = len > 0 ? this.timeRange[0][1] : ''
    currentData.timeStartTwo = len > 1 ? this.timeRange[1][0] : ''
    currentData.timeEndTwo = len > 1 ? this.timeRange[1][1] : ''
    currentData.timeStartThree = len > 2 ? this.timeRange[2][0] : ''
    currentData.timeEndThree = len > 2 ? this.timeRange[2][1] : ''
    currentData.timeStartFour = len > 3 ? this.timeRange[3][0] : ''
    currentData.timeEndFour = len > 3 ? this.timeRange[3][1] : ''
    currentData.timeStartFive = len > 4 ? this.timeRange[4][0] : ''
    currentData.timeEndFive = len > 4 ? this.timeRange[4][1] : ''
    currentData.timeStartSix = len > 5 ? this.timeRange[5][0] : ''
    currentData.timeEndSix = len > 5 ? this.timeRange[5][1] : ''
    delete currentData.timeRange
    this.elForm.validate().then(() => {
      this.$axios.post(this.$apis.bracelet.updateWorkerRules, currentData).then(() => {
        this.dialogVisible = false
        this.$message.success('保存成功')
        this.loadData()
      })
    })
  }

  cancel () {
    this.dialogVisible = false
  }
}
